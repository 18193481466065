import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircle from '@mui/icons-material/AccountCircle';

const Login = () => {
  // const [open, setOpen] = useState(true);
  const [open] = useState(true);
  const [input, setInput] = useState({
    user: '',
    password: ''
  });
  const [err, setErr] = useState({
    user: false,
    password: false,
  });
  const [message, setMessage] = useState('')
  // let {num ,setNum } = useContext(NumContext);

  const handleLogin = () => {
    // console.log(input)
    if (input.user === '' && input.password === '') {
      setErr({...err,user:true,password:true})
    }else if(input.user === '' && input.password !== '') {
      setErr({...err,user:true,password:false})
    }else if(input.user !== '' && input.password === '') {
      setErr({...err,user:false,password:true})
    }else {
      setErr({...err,user:false,password:false})
      fetch('https://hovs.uzett.com/api/login',{
          method:'post',
          headers:{
              'Accept':'application/json,text/plain,*/*',/* 格式限制：json、文本、其他格式 */
              'Content-Type':'application/x-www-form-urlencoded'/* 请求内容类型 */,
          },
          credentials: 'include',
          body:`user=${input.user}&pwd=${input.password}`
      }).then((response)=>{
        return response.json()
      }).then((data)=>{
          // console.log(data)
          if (data.code !== 200) {
            setMessage('登录失败，账号或密码错误！')
            setInput({...input,password: ''})
          }else {
            window.location.reload();
          }
      }).catch(function(error){
          console.log(error)
      })

    }

  };

  const handleChange = (props) => (event) => {
    setInput({ ...input, [props]: event.target.value});
    if (input.user !== '') {
      setErr({...err,user:false})
    }
    if (input.password !== '') {
      setErr({...err,password:false})
    }
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      {/* onClose={handleClose} */}
      <Dialog open={open} fullWidth maxWidth='xs'>
        <DialogTitle>登录</DialogTitle>
        <DialogContent>
          <DialogContentText>
            您的密码将会被加密存储
          </DialogContentText>
          <Typography variant="subtitle1" gutterBottom component="div" sx={{color: '#F44336'}}>{message}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField value={input.user} onChange={handleChange('user')} margin="dense" error={err.user} fullWidth id="input-with-sx" label="学号" variant="standard" />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <LockIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField value={input.password} onChange={handleChange('password')} margin="dense" error={err.password} fullWidth id="input-with-sx" label="密码" variant="standard" />
          </Box>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <Button onClick={handleLogin}>登录</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Login