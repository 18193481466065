import React, { Component } from 'react'
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MasksIcon from '@mui/icons-material/Masks';

class COVID_19_state extends Component {
  constructor(props) {
    super(props)
    // const [c19_state, set_c19_state] = useState([])
    this.state = {
      c19_state: {}
    }
    // this.state = {
    //   c19_state: {
    //     province: '',
    //     confirm: 0,
    //     confirm_add: 0,
    //     date: ''
    //   }
    // }
  }
  componentWillMount() {
    fetch('https://api.inews.qq.com/newsqa/v1/query/pubished/daily/list?province=%E7%A6%8F%E5%BB%BA').then(res => {
        return res.json()
        // console.log(res.json())
    }).then(data => {
      // console.log(data.data.pop())
      // this.setState({c19_state: data.data.pop()});
      // set_c19_state(data.data.pop())
      // c19 = data.data.pop()
      this.setState({
        c19_state: data.data.pop()
      })
    })
  }

  render() {
    return (
      // <div>
        <ListItem
        disablePadding 
        secondaryAction={
          <Box>
            {/* {this.state.c19_state.year} */}
            {/* <Typography color="#ff9800" variant="h6" gutterBottom component="div" sx={{marginBottom: '0px', textAlign: 'center'}}>
              新增 {this.state.c19_state.confirm_add} 人
            </Typography> */}
            {/* <Chip variant="outlined" color="warning" size="small" label="紧急" icon={<PriorityHighIcon />} /> */}
          </Box>
        }>
          <ListItemButton>
            <ListItemIcon>
              <MasksIcon color='info' />
            </ListItemIcon>
            <ListItemText
              primary='疫情动态'
              secondary={<span>{this.state.c19_state.year} {this.state.c19_state.date} {this.state.c19_state.province} 累计确诊 {this.state.c19_state.confirm} 人；<br/>新增确诊 {this.state.c19_state.confirm_add} 人；新增死亡 {this.state.c19_state.newDead} 人 </span>}
            />
          </ListItemButton>
        </ListItem>
      // </div>
    )
  }
}

export default COVID_19_state