import React from 'react'
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import HomeWork from './pages/HomeWork'
// import logo from './logo.svg';
import Navbar from './components/Navbar'
import Login from './components/Login';
import { Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';

import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoIcon from '@mui/icons-material/Info';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import BookIcon from '@mui/icons-material/Book';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HubIcon from '@mui/icons-material/Hub';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props)
    // const [c19_state, set_c19_state] = useState([])
    this.state = {
      c19_state: {},
      darkMode: true,
      value: 0,
      showLogin: true,
      user: null,
    }
    
  }

  componentWillMount() {
    // console.log('c'+document.cookie)
    fetch('https://hovs.uzett.com/api',{
      // headers: {'Cookie': global.cookie},
      credentials: 'include'
    }).then(res => {
        return res.json()
        // console.log(res.json())
    }).then(data => {
      // console.log(data)
      if (data.code === 200) {
        this.setState({showLogin:false,user:data})
      }
      
    })

    
  }

  render() {
    const light = createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#017AFF',
          darker: '#000000',
        },
        info: {
          main: '#8C8C8C',
          darker: '#053e85',
        },
        background: {
          default: '#F2F1F6',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#222222'
        },
      },
    });
    const dark = createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: '#017AFF',
          darker: '#000000',
        },
        info: {
          main: '#017AFF',
          darker: '#053e85',
        },
        background: {
          default: '#000000',
          paper: '#252429',
        },
        icon: '#017AFF'
      },
    });

    const appliedTheme = this.state.darkMode ? light : dark
    return (
      <div className="App">
        <ThemeProvider theme={appliedTheme}>
          <Box sx={{bgcolor: 'background.default',color: 'text.primary',height: '100vh'}}>
            <Navbar darkMode={ this.state.darkMode ? true : false } />
            <Container maxWidth="sm" style={{padding: '10px'}}>
              {/* {this.state.showLogin?<Login />:<HomeList user={this.state.user} />} */}
              {this.state.showLogin?<Login />:(
                <Routes>
                  <Route path="/" element={<Home user={this.state.user} />} />
                  <Route path="/homework" element={<HomeWork />} />
                  {/* <Route path="about" element={<About />} /> */}
                </Routes>
              )}

              
            </Container>
            {/* <button onClick={() => setDarkMode(!darkMode)}>切换主题</button> */}
            <div style={{ width: '30px',height: '80px' }} onClick={() => this.setState({darkMode:!this.state.darkMode})}></div>
            <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
              <BottomNavigation
                showLabels
                value={this.state.value}
                onChange={(event, newValue) => {
                  // setValue(newValue);
                  this.setState({value: newValue})
                }}
                sx={{backgroundColor: this.state.darkMode ? '#F8F8F8' : '#000000'}}
              >
                <BottomNavigationAction label="首页" icon={<AssessmentIcon />} />
                <BottomNavigationAction label="消息" icon={<MessageIcon />} />
                <BottomNavigationAction label="设置" icon={<SettingsIcon />} />
              </BottomNavigation>
            </Box>
          </Box>
        </ThemeProvider>
        
      </div>
    );
  }
}

export default App;
