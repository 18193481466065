import React from 'react'
import Navbar from '../components/Navbar'
import COVID19State from '../components/COVID_19_state'
import Login from '../components/Login';
import { Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';

import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoIcon from '@mui/icons-material/Info';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import BookIcon from '@mui/icons-material/Book';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HubIcon from '@mui/icons-material/Hub';


function HomeList(props) {
  const user = props.user.user;
  return (
    <div>
      <div style={{paddingBottom: '10px'}}><Alert icon={<SentimentVerySatisfiedIcon fontSize="inherit" />} severity="success">恭喜！作业全部完成🎉</Alert></div>
      <Box sx={{bgcolor: 'background.paper'}} borderRadius={2}>
        
        <nav aria-label="main mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary={<span>欢迎回来，{user}</span>}
                secondary="晚上好~"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem 
            disablePadding 
            secondaryAction={
              <Box>
                <Typography variant="h6" gutterBottom component="div" sx={{marginBottom: '0px', textAlign: 'right'}}>
                  A+
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  考试与成绩
                </Typography>
              </Box>
            }>
              <ListItemButton>
                <ListItemIcon>
                  <ViewTimelineIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="今日课程"
                  secondary="今日无课程"
                />
              </ListItemButton>
            </ListItem>
            <ListItem 
            disablePadding 
            secondaryAction={
              <Box>
                <Typography variant="h6" gutterBottom component="div" sx={{marginBottom: '0px', textAlign: 'right'}}>
                  🎉
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  全部完成
                </Typography>
              </Box>
            }>
              <ListItemButton>
                <ListItemIcon>
                  <BookIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="作业"
                  secondary={<>截止日期 2022年2月17日<br/>已全部完成 25 / 25 项</>}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem disabled
            disablePadding 
            secondaryAction={
              <Box>
                <Typography variant="h6" gutterBottom component="div" sx={{marginBottom: '0px', textAlign: 'center'}}>
                  ￥--.--
                </Typography>
              </Box>
            }>
              <ListItemButton>
                <ListItemIcon>
                  <CreditCardIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="校园卡余额"
                  secondary="本校不支持"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding 
            secondaryAction={
              <Box>
                {/* <Typography color="#ff9800" variant="h6" gutterBottom component="div" sx={{marginBottom: '0px', textAlign: 'center'}}>
                  紧急
                </Typography> */}
                {/* <Chip variant="outlined" color="warning" size="small" label="紧急" icon={<PriorityHighIcon />} /> */}
              </Box>
            }>
              <ListItemButton>
                <ListItemIcon>
                  <InfoIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary={<span><Chip variant="outlined" color="warning" size="small" label="紧急" icon={<PriorityHighIcon />} ></Chip> 教务处通知</span>}
                  secondary="学生务必将核酸检测码发给班主任"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem
            disablePadding 
            secondaryAction={
              <Box>
                {/* <Typography color="#ff9800" variant="h6" gutterBottom component="div" sx={{marginBottom: '0px', textAlign: 'center'}}>
                  紧急
                </Typography> */}
                {/* <Chip variant="outlined" color="warning" size="small" label="紧急" icon={<PriorityHighIcon />} /> */}
              </Box>
            }>
              <ListItemButton>
                <ListItemIcon>
                  <HubIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary='核酸检测结果'
                  secondary='近7天无记录'
                />
              </ListItemButton>
            </ListItem>
            <COVID19State />
          </List>
        </nav>
      </Box>
    </div>
  )
}

class Home extends React.Component {
  
  constructor(props) {
    super(props)
    // const [c19_state, set_c19_state] = useState([])
    this.state = {
      c19_state: {},
      darkMode: true,
      value: 0,
      showLogin: true,
      user: null,
    }
    
  }
  

  // componentWillMount() {
  //   // console.log('c'+document.cookie)
  //   fetch('https://hovs.uzett.com/api',{
  //     // headers: {'Cookie': global.cookie},
  //     credentials: 'include'
  //   }).then(res => {
  //       return res.json()
  //       // console.log(res.json())
  //   }).then(data => {
  //     // console.log(data)
  //     if (data.code === 200) {
  //       this.setState({showLogin:false,user:data})
  //     }
      
  //   })

    
  // }

  render() {
    // const [darkMode, setDarkMode] = useState(false)
    // const [value, setValue] = useState(0);
    
    const light = createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#017AFF',
          darker: '#000000',
        },
        info: {
          main: '#8C8C8C',
          darker: '#053e85',
        },
        background: {
          default: '#F2F1F6',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#222222'
        },
      },
    });
    const dark = createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: '#017AFF',
          darker: '#000000',
        },
        info: {
          main: '#017AFF',
          darker: '#053e85',
        },
        background: {
          default: '#000000',
          paper: '#252429',
        },
        icon: '#017AFF'
      },
    });
  
    const appliedTheme = this.state.darkMode ? light : dark
    return (
      <div>
        <ThemeProvider theme={appliedTheme}>
          <Box sx={{bgcolor: 'background.default',color: 'text.primary',height: '100vh'}}>
            {/* <Navbar darkMode={ this.state.darkMode ? true : false } /> */}
            <Container maxWidth="sm" style={{padding: '10px'}}>
              {/* {this.state.showLogin?<Login />:<HomeList user={this.state.user} />} */}
              <HomeList user={this.props.user} />
            </Container>
            {/* <button onClick={() => setDarkMode(!darkMode)}>切换主题</button> */}
            <div style={{ width: '30px',height: '80px' }} onClick={() => this.setState({darkMode:!this.state.darkMode})}></div>
            <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
              <BottomNavigation
                showLabels
                value={this.state.value}
                onChange={(event, newValue) => {
                  // setValue(newValue);
                  this.setState({value: newValue})
                }}
                sx={{backgroundColor: this.state.darkMode ? '#F8F8F8' : '#000000'}}
              >
                <BottomNavigationAction label="首页" icon={<AssessmentIcon />} />
                <BottomNavigationAction label="消息" icon={<MessageIcon />} />
                <BottomNavigationAction label="设置" icon={<SettingsIcon />} />
              </BottomNavigation>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    )
  }
}

export default Home