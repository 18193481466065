import React from 'react'
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';

import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoIcon from '@mui/icons-material/Info';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import BookIcon from '@mui/icons-material/Book';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HubIcon from '@mui/icons-material/Hub';

function HomeWorkList() {
  return (
    <div>
      <Box sx={{bgcolor: 'background.paper'}} borderRadius={2}>
        
        <nav aria-label="main mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary="作业发布"
                secondary="晚上好~"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem 
            disablePadding 
            secondaryAction={
              <Box>
                <Typography variant="h6" gutterBottom component="div" sx={{marginBottom: '0px', textAlign: 'right'}}>
                  有效
                </Typography>
                {/* <Typography variant="caption" display="block" gutterBottom>
                  考试与成绩
                </Typography> */}
              </Box>
            }>
              <ListItemButton>
                <ListItemIcon>
                  <ViewTimelineIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="有效期"
                  secondary="2022年3月8日"
                />
              </ListItemButton>
            </ListItem>
            <ListItem 
            disablePadding 
            >
              <ListItemButton>
                <ListItemIcon>
                  <BookIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="发布信息"
                  secondary={<div>
                    <Stack direction="row" spacing={1}>
                      <Chip label="七年级 2班" />
                      <Chip label="210247 饶志伟" avatar={<Avatar alt="Natacha" src="https://gitee.com/yuzett/blogimage/raw/master/img/202203081912655.png" />} />
                    </Stack>
                  </div>}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem
            disablePadding 
            secondaryAction={
              <Box>
                {/* <Typography color="#ff9800" variant="h6" gutterBottom component="div" sx={{marginBottom: '0px', textAlign: 'center'}}>
                  紧急
                </Typography> */}
                <Chip variant="outlined" color="warning" size="small" label="紧急" icon={<PriorityHighIcon />} />
              </Box>
            }>
              <ListItemButton>
                <ListItemIcon>
                  <InfoIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary={<span>教务处通知</span>}
                  secondary="明日核酸检测，请及时生成检测码！"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary="语文作业"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="试卷"
                  secondary="P85-89"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary="数学作业"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="优化设计"
                  secondary="根号"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="练习本"
                  secondary="书本P47 1. 2.题"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary="英语"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="抄单词"
                  secondary="draw-also"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="抄课文"
                  secondary="P14 2a"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="试卷"
                  secondary="P4"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="练习册"
                  secondary="SC"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary="历史作业"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="预习"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="优化设计"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary="地理作业"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="优化设计"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary="政治作业"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="优化设计"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem>
              {/* <ListItemButton> */}
              <ListItemText
                primary="生物作业"
              />
              {/* </ListItemButton> */}
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="练习册"
                  secondary="血液 第一课时"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="预习"
                  secondary="血液 第二课时"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="预习"
                  secondary="血液循环 第一课时"
                />
              </ListItemButton>
            </ListItem>
            <ListItem
            disablePadding >
              <ListItemButton>
                <ListItemIcon>
                  <EditIcon color='info' />
                </ListItemIcon>
                <ListItemText
                  primary="导学案写完"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </div>
  )
}

function HomeWork() {
  return (
    <div>
      <Alert severity="warning">该功能尚未开发完善！</Alert>
      {/* <div style={{paddingTop: "15px"}}>
        <Typography variant="h5" gutterBottom component="div">
          作业发布
        </Typography>
      </div>
      <div>
        <Stack direction="row" spacing={1}>
          <Chip label="七年级 2班" />
          <Chip label="210247 饶志伟" avatar={<Avatar alt="Natacha" src="https://gitee.com/yuzett/blogimage/raw/master/img/202203081912655.png" />} />
          <Chip label="有效期 3/8" />
        </Stack>
      </div> */}
      <div style={{paddingTop: "15px"}}>
        <HomeWorkList/>
      </div>
    </div>
  )
}

export default HomeWork