import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

function Navbar(props) {
  const darkMode = props.darkMode;
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary" sx={{ 
            boxShadow: 0,
            backgroundImage: 'none',
            backgroundColor: darkMode ? '#F8F8F8' : '#000000',
            // borderBottom: '1px solid',
            // borderBottomColor: darkMode ? '#D3D2D5' : '#000000'
          }} >
          <Toolbar sx={{backgroundColor: darkMode ? '#F8F8F8' : '#000000'}}>
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{ m: '0 auto' }}
            >
              <NotificationsNoneIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{textAlign: 'center'}} color='text.primary'>
              鸿数
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            {/* <Button color="inherit" onClick={this.setDarkMode({darkMode: 'Hello'})}>Login</Button> */}
            {/* {this.state} */}
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{ m: '0 auto' }}
            >
              <EditIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  )
}

export default Navbar